const PreviewImage = (props: any) => {
    const params = new URLSearchParams(props.location.search);
    const imgdt = params.get("imgdt") || '';
    return (
        <div>
            <img src={imgdt} style={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: 'calc(100vh - 60px)', backgroundPosition: 'center', paddingBottom:'10px' }} alt="" />
        </div>
    )
}

export default PreviewImage