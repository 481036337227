import React from 'react';
import PreviewImage from './components/itemAssetsView/PreviewImage';

interface iroute {
    path: string
    name: string,
    component: any
}

const HomePage = React.lazy(() => import("./pages/homePage/HomePage"));
const BannerUpload = React.lazy(() => import("./pages/bannerUpload/BannerUpload"));
const Department = React.lazy(() => import("./pages/department/Department"));
const SubDepartment = React.lazy(() => import("./pages/subDepartment/SubDepartment"));
const Category = React.lazy(() => import("./pages/category/Category"));
const Brand = React.lazy(() => import("./pages/brand/Brand"));
const Series = React.lazy(() => import("./pages/series/Series"));
const ItemMaster = React.lazy(() => import("./pages/itemMaster/ItemMaster"));
const ItemAsset = React.lazy(() => import("./pages/itemAsset/ItemAsset"));
const ItemSpecs = React.lazy(() => import("./pages/itemSpecs/ItemSpecs"));
const ItemSeller = React.lazy(() => import("./pages/itemSeller/ItemSeller"));
const Offer = React.lazy(() => import("./pages/offer/Offer"));
const ItemMeta = React.lazy(() => import("./pages/itemMetaData/ItemMeta"));
const BestSeller = React.lazy(() => import("./pages/bestSeller/BestSeller"));
const OtherMeta = React.lazy(() => import("./pages/metaData/MetaData"));
const ImageUpload = React.lazy(() => import("./pages/ImageUpload/ImageUpload"));
const ContentUpload = React.lazy(() => import("./pages/contentUpload/ContentUpload"));
const UserManagement = React.lazy(() => import("./pages/userManagement/UserManagement"));
const ProductApproval = React.lazy(() => import("./pages/productApproval/ProductApproval"));
const ApprovalDetail = React.lazy(() => import("./pages/productApprovalDetail/ProductApprovalDetail"));
const ItemRegistry = React.lazy(() => import("./pages/itemRegistry/ItemRegistry"));


const routes: iroute[] = [
    { path: "/home", name: "Home", component: HomePage },
    { path: "/bannerUpload", name: "BannerUpload", component: BannerUpload },
    { path: "/department", name: "Department", component: Department },
    { path: "/subDepartment", name: "SubDepartment", component: SubDepartment },
    { path: "/category", name: "Category", component: Category },
    { path: "/brand", name: "Brand", component: Brand },
    { path: "/series", name: "Series", component: Series },
    { path: "/itemMaster", name: "ItemMaster", component: ItemMaster },
    { path: "/itemAsset", name: "ItemAsset", component: ItemAsset },
    { path: "/itemSpecs", name: "ItemSpecs", component: ItemSpecs },
    { path: "/itemSeller", name: "ItemSeller", component: ItemSeller },
    { path: "/offer", name: "Offer", component: Offer },
    { path: "/itemMetaData", name: "ItemMetaData", component: ItemMeta },
    { path: "/bestSeller", name: "BestSeller", component: BestSeller },
    { path: "/metaData", name: "OtherMeta", component: OtherMeta },
    { path: "/imageUpload", name: "ImageUpload", component: ImageUpload },
    { path: "/contentUpload", name: "ContentUpload", component: ContentUpload },
    { path: "/userManagement", name: "UserManagement", component: UserManagement },
    { path: "/productApproval", name: "ProductApproval", component: ProductApproval },
    { path: "/approvalDetail", name: "ApprovalDetail", component: ApprovalDetail },
    { path: "/imagePreview", name: "ImagePreview", component: PreviewImage },
    { path: "/itemRegistry", name: "ItemRegistry", component: ItemRegistry },
]

export default routes;