import axios from 'axios';
import { hideLoadingScreen, showLoadingScreen } from '../redux/loadingScreen/loadingScreenActions';
import { showMessageBox } from '../redux/messageBox/messageBoxAction';
import { store } from '../redux/store';
import { resetUserSession } from '../redux/userSession/UserSession_Actions';

const { REACT_APP_BACKEND_URL, REACT_APP_API_VERSION } = process.env;

const jkoaClient = axios.create({
    baseURL: `${REACT_APP_BACKEND_URL}/${REACT_APP_API_VERSION}`,
    // timeout: 1000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    withCredentials: true
});


export const get = (path: string, params: {}, hideLoader: boolean) => {
    return new Promise((resolve) => {
        if (!hideLoader) store.dispatch(showLoadingScreen());
        jkoaClient.get(path, { params: params })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                console.log(error);
                window.sessionStorage.clear();
                if (error.response === undefined) {
                    let messageBox = {
                        show: true,
                        title: "Oops!",
                        className: "error",
                        content: "Something went wrong. We're working on getting this fixed as soon as we can.\nYou may be able to try again.",
                        isConfirmation: false,
                        callBackFunction: () => { store.dispatch(resetUserSession()); }//change
                    }
                    store.dispatch(showMessageBox(messageBox));
                }
                else if (error.response.status === 401) {
                    let messageBox = {
                        show: true,
                        title: "Your session expired!",
                        className: "warning",
                        content: "Sorry, your session has expired.",
                        isConfirmation: false,
                        callBackFunction: () => { store.dispatch(resetUserSession()); }
                    }
                    store.dispatch(showMessageBox(messageBox));
                }
                else {
                    let messageBox = {
                        show: true,
                        title: "Oops!",
                        className: "error",
                        content: "Something went wrong. We're working on getting this fixed as soon as we can.\nYou may be able to try again.",
                        isConfirmation: false,
                        callBackFunction: () => { store.dispatch(resetUserSession()); }//
                    }
                    store.dispatch(showMessageBox(messageBox));
                }
            })
            .then(() => {
                store.dispatch(hideLoadingScreen());
            })
    })
}

export const post = (path: string, body: {}, hideLoader: boolean) => {
    return new Promise((resolve) => {
        if (!hideLoader) store.dispatch(showLoadingScreen());
        jkoaClient.post(path, body)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                console.log(error);
            })
            .then(() => {
                store.dispatch(hideLoadingScreen());
            })
    })
}
