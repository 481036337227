import { combineReducers } from "redux";
import { loadingScreenReducer } from "./loadingScreen/loadingScreenReducer";
import { responsiveReducer } from './responsive/responsiveReducer';
import { messageBoxReducer } from './messageBox/messageBoxReducer';
import { UserSession_Reducer } from "./userSession/UserSession_Reducer";
import { AzureConfiguration_Reducer } from "./azureConfiguration/AzureConfiguration_Reducer";
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

const persistConfig = {
    key: 'root',
    storage: storageSession,
    blacklist: [/**'Prevent persist states'*/]
}

const rootReducer = combineReducers({
    loadingScreen: loadingScreenReducer,
    responsive: responsiveReducer,
    messageBox: messageBoxReducer,
    UserSession: UserSession_Reducer,
    AzureConfiguration: AzureConfiguration_Reducer,
})

export default persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>