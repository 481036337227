export interface IColor {
    primaryColor: string,
    secondaryColor: string
}

export const colorCodes: IColor[] = [
    { primaryColor: "#EAF9E6", secondaryColor: "#2E5834" },
    { primaryColor: "#FFDCD6", secondaryColor: "#E54338" },
    { primaryColor: "#FFE5AC", secondaryColor: "#E3AC32" },
    { primaryColor: "#C6DFFF", secondaryColor: "#1955FD" },
]

export const socialMediaLink = {
    facebook: "https://www.facebook.com/jkoasl",
    instagram: "https://instagram.com/j_k_o_a?utm_medium=copy_link",
    linkedin: "https://www.linkedin.com/company/john-keells-office-automation-pvt-ltd-",
    youtube: "https://www.youtube.com/user/JKOAPvtLtd",
}

export const productListingType = {
    category: "category",
    brand: "brand",
    search: "search"
}

export enum ErrorTypes {
    Error = 1,
    Information = 2,
    Notification = 3,
    Success = 4,
    Warning = 5
}