import React, { Component } from 'react';
import { Row, Col, ModalBody, Modal, Button } from "react-bootstrap";
import './MessageBox.scss';

/*-- Imports for redux --*/
import { useSelector } from 'react-redux';
import { RootState, useReduxDispatch } from '../../../redux/store';
import { resetMessageBox } from '../../../redux/messageBox/messageBoxAction';

function MessageBox() {
    const dispatch = useReduxDispatch();
    const messageBoxData = useSelector((state: RootState) => state.messageBox);

    function handleCallBackFunction(response: any) {
        if (messageBoxData.callBackFunction != null) {
            dispatch(resetMessageBox());
            messageBoxData.callBackFunction(response);
        } else {
            dispatch(resetMessageBox());
        }
    };

    const headerIcon = messageBoxData.isConfirmation ? "fas fa-question-circle"
        : (messageBoxData.className === "error" || messageBoxData.className === "warning") ? "fas fa-exclamation-circle"
            : messageBoxData.className === "success" ? "fas fa-check-circle" : "fas fa-info-circle";
    return (
        <div>
            <Modal size="sm" centered className="message-box" show={messageBoxData.show === undefined ? false : messageBoxData.show}>
                <ModalBody>
                    <Row className={`message-box-header-${messageBoxData.className}`}>
                        <Col md="12">
                            <i id="id_message_box_header_icon" className={headerIcon}></i>
                            <h5>{messageBoxData.title === undefined ? "" : messageBoxData.title}</h5>
                        </Col>
                    </Row>
                    <Row className="message-box-body">
                        <Col md="12">
                            <p style={{ whiteSpace: "pre-line" }}>
                                {messageBoxData.content === undefined ? "" : messageBoxData.content}
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ margin: 0, float: "right", paddingBottom: "15px" }}>
                        {
                            messageBoxData.className === "success" ?
                                <Col md="12">
                                    <Button className="new-btn-primary" style={{ display: messageBoxData.isConfirmation ? "" : "none", marginRight: "5px" }} onClick={() => { handleCallBackFunction(true) }}>Yes</Button>
                                    <Button className="new-btn-secondary" style={{ display: messageBoxData.isConfirmation ? "" : "none", marginRight: "5px" }} onClick={() => { handleCallBackFunction(false) }}>No</Button>
                                    <Button className="new-btn-primary" style={{ display: messageBoxData.isConfirmation ? "none" : "" }} onClick={() => { handleCallBackFunction(true) }}>Ok</Button>
                                </Col>
                                : <Col md="12">
                                    <Button className="new-btn-sucess" style={{ display: messageBoxData.isConfirmation ? "" : "none", marginRight: "5px" }} onClick={() => { handleCallBackFunction(true) }}>Yes</Button>
                                    <Button className="new-btn-danger" style={{ display: messageBoxData.isConfirmation ? "" : "none", marginRight: "5px" }} onClick={() => { handleCallBackFunction(false) }}>No</Button>
                                    <Button className="new-btn-secondary" style={{ display: messageBoxData.isConfirmation ? "none" : "" }} onClick={() => { handleCallBackFunction(true) }}>Ok</Button>
                                </Col>
                        }
                    </Row>
                </ModalBody>
            </Modal>

        </div>
    )
};

export default MessageBox;

