import React, { useEffect, useState } from "react";
import { RootState, useReduxDispatch } from '../../redux/store';
import { get, post } from '../../utilities/apiClient'
import { Row, Col, Card, CardHeader, CardBody, Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Table } from 'reactstrap';
import ErrorSpan from '../../components/common/errorSpan/ErrorSpan';
import { useDispatch, useSelector } from "react-redux";
import { showMessageBox } from "../../redux/messageBox/messageBoxAction";
import "react-datepicker/dist/react-datepicker.css";
import { UploadFilesToBlobStorage } from '../../utilities/blobFileUpload';
import {
    CardGroup, Container, Form, InputGroup,
    InputGroupAddon, InputGroupText, Spinner, FormText
} from 'reactstrap';
import { UserSessionState } from "../../redux/userSession/UserSession_Reducer";
import { addUserSession } from "../../redux/userSession/UserSession_Actions";
import { Redirect, useHistory } from "react-router-dom";

function Login() {
    const dispatchMsg = useDispatch();
    const dispatch = useReduxDispatch();
    const [redirectUrl, setRedirectUrl] = useState<string>("");
    const [redirectAppId, setRedirectAppId] = useState<string>("");
    const [readOnly, setReadOnly] = useState<string>("readOnly");
    const [userName, setUserName] = useState<string>("");
    const [logo, setLogo] = useState<string>("https://sajkoaonline.blob.core.windows.net/jkoa/other/JKOA-logo.png");
    const [userPassword, setUserPassword] = useState<string>("");
    const [userNameError, setUserNameError] = useState<string>("");
    const [userPasswordError, setUserPasswordError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(false);
    const [isGuest, setIsGuest] = useState<boolean>(false);
    const [user, setUser] = useState<any>({});
    const [messageObj, setMessageObj] = useState<any>();
    const [userLogged, setUserLogged] = useState(false);
    const userSessionData = useSelector((state: RootState) => state.UserSession);
    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
            // this.setState({ readOnly: '' })
            setReadOnly('')
        }, 300);
    }, [])

    useEffect(() => {
        if (userLogged == true && userSessionData.userId != 0) {
            // history.push("/home")
            window.location.replace('/home');
        }
    }, [userLogged]);

    function validateInputs() {
        let isValid = true;
        if (userName === "") {
            isValid = false;
            setUserNameError("User name can not be empty.")
        }
        if (userPassword === "") {
            isValid = false;
            setUserPasswordError("Password can not be empty.")
        }
        return isValid;
    };

    function loginClick() {
        setUserNameError("");
        setUserPasswordError("");
        if (validateInputs()) {
            setIsLoading(true);
            setIsLoadingScreen(true);
            post("User/UserLogin", { Username: userName, Password: userPassword }, true)
                .then(res => {
                    var response: any = res;
                    if (response.data.statusCode === 200) {
                        // setUser(response.data.result)
                        dispatch(addUserSession(response.data.result))
                            .then(() => {
                                debugger
                                console.log(response.data.result)
                                if (response.data.result.userId != 0) {
                                    setUserLogged(true);
                                }
                            })
                    }
                    else {
                        console.log(response.data.errorList)
                        // const msgBox = {
                        //     show: true,
                        //     title: "Item Search",
                        //     className: "error",
                        //     content: response.data.errorList[0].statusMessage,
                        //     isConfirmation: false,
                        //     callBackFunction: null
                        // }
                        // dispatchMsg(showMessageBox(msgBox))
                        setUserPasswordError(response.data.errorList[0].statusMessage);
                        setIsLoading(false);
                    }
                });

        } else {
            setIsLoading(false);
            setIsLoadingScreen(false);
        }

    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: "#E4E5E6" }}>

            <Container>
                <Row className="justify-content-center">
                    <Col md="8">
                        <CardGroup>
                            <Card hidden={isGuest} className="p-4 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <CardBody>
                                    <Form autoComplete='off'>
                                        <h1>Login</h1>
                                        <p className="text-muted">Sign In to your account</p>
                                        <Row className="mb-3 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <InputGroup >
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText >
                                                        <i className="fa fa-user fa-2x"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input autoComplete="off" placeholder="Username" value={userName} onChange={e => setUserName(e.target.value)} />
                                            </InputGroup>
                                            <FormText style={{ textAlign: 'left' }} className="help-block">
                                                <span style={{ color: "red", fontSize: '11px' }}>{userNameError}</span>
                                            </FormText>
                                        </Row>
                                        <Row className="mb-3 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <InputGroup >
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-lock fa-2x"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input autoComplete="off" type="password" placeholder="Password" value={userPassword} onChange={e => setUserPassword(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            loginClick();
                                                        }
                                                    }} />
                                            </InputGroup>
                                            <FormText style={{ textAlign: 'left' }} className="help-block">
                                                <span style={{ color: "red", fontSize: '11px' }}>{userPasswordError}</span>
                                            </FormText>
                                        </Row>
                                        <Row className="mb-3 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <Button type='button' size="sm" color="success" style={{ backgroundColor: '#79BF43', borderColor: '#79BF43' }} className="px-4 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" onClick={() => loginClick()}>
                                                {isLoading ? <Spinner size="sm" /> : "Login"}
                                            </Button>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Card className="text-white py-5 d-md-down-none d-none d-lg-block" style={{ width: '44%', backgroundColor: "#79BF43" }}>
                                <CardBody className="text-center">
                                    <div>
                                        <img src={logo} style={{ width: '80%', paddingTop: '25px' }} alt="Logo" />
                                    </div>
                                </CardBody>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
            </Container>
            {/* <Alert messageObj={messageObj} />
                <LoadingScreen isVisible={isLoadingScreen} /> */}

        </div>
    )
}

export default Login;