export enum responsiveActionTypes {
    UpdateResponsiveType = "UPDATE_RESPONSIVE",
    ResetResponsiveType = "RESET_RESPONSIVE",
}

export const updateResponsive = (responsive: any) => ({
    type: responsiveActionTypes.UpdateResponsiveType,
    payload: responsive
});

export const resetResponsive = () => ({
    type: responsiveActionTypes.ResetResponsiveType
});