import { Action, applyMiddleware, createStore, compose } from "redux";
import thunk, { ThunkMiddleware, ThunkDispatch } from "redux-thunk";
import rootReducer from './rootReducer';
import { useDispatch } from 'react-redux';

import { LoadingScreenState } from "./loadingScreen/loadingScreenReducer";
import { ResponsiveState } from './responsive/responsiveReducer';
import { messageBoxInterface } from './messageBox/messageBoxReducer';
import { UserSessionState } from "./userSession/UserSession_Reducer";
import { AzureConfigurationState } from "./azureConfiguration/AzureConfiguration_Reducer";
import { persistStore } from 'redux-persist';

export type RootState = ReturnType<typeof rootReducer>

type ReduxDispatch = ThunkDispatch<RootState, any, Action>;
export function useReduxDispatch(): ReduxDispatch {
    return useDispatch<ReduxDispatch>();
}

interface rootState extends
    ResponsiveState,
    messageBoxInterface,
    LoadingScreenState,
    UserSessionState,
    AzureConfigurationState { }

export interface DispatchAction extends Action {
    payload: Partial<rootState>;
}

const middleware = thunk as ThunkMiddleware<RootState, DispatchAction>;

const ReduxDevTools =
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__();

let _compose = applyMiddleware(middleware);
// let _compose = compose(applyMiddleware(middleware), ReduxDevTools);

export const store = createStore(
    rootReducer,
    _compose
);

export const persistedState = persistStore(store)
export default { store, persistedState };

