import { AzureConfigurationActionsTypes } from "./AzureConfiguration_Actions";

export interface AzureConfigurationState {
    blobStorageAccount: string;
    blobStorageSASUrl: string;
    blobStorageBaseUrl: string;
};

const initialState: AzureConfigurationState = {
    blobStorageAccount: "",
    blobStorageSASUrl: "",
    blobStorageBaseUrl: ""
};

export const AzureConfiguration_Reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case AzureConfigurationActionsTypes.AddAzureConfigurationType:
            return {
                ...state,
                blobStorageAccount: action.payload.blobStorageAccount,
                blobStorageSASUrl: action.payload.blobStorageSASUrl,
                blobStorageBaseUrl: action.payload.blobStorageBaseUrl
            };
        case AzureConfigurationActionsTypes.ResetAzureConfigurationType:
            return initialState;
        default:
            return state;
    }
};