export enum responsiveScreenSizesEnum {
    tabScreenSize = 768,
    mobileLargeScreen = 425,
    mobileSmallScreen = 375,
}

export enum ErrorTypes {
    Error = 1,
    Information = 2,
    Notification = 3,
    Success = 4,
    Warning = 5
}

export enum BannerTypes {
    Carousel = 1,
    ProductListing = 2,
    ContactUs = 3
}